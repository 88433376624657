<div class="professions">

    <div class="professions-wallpaper">

        <div class="professions-background">

            <div class="professions-wrapper">
                <div class="image-container">
                    <img class="ecology-image" src="../../../../assets/profession.jpg" />
                </div>


                <div class="content-container">
                    <p class="professions-content">
                        Având deja pregătirea necesară ei pot să se angajeze în diferite structuri din domeniul
                        protecţiei mediului:
                    </p>

                    <div class="professions-content">
                        <ul>
                            <li>Staţii Meteorologice</li>
                            <li>Staţii de tratare a apei potabile </li>
                            <li>Staţii de epurare a apei uzate</li>
                            <li>Agenţia de Protecţia Mediului</li>
                            <li>Agenţia Apele Române</li>
                            <li>Laboratoare de analize în domeniul protecţiei mediului</li>
                        </ul>
                    </div>


                </div>




            </div>



        </div>

    </div>

</div>