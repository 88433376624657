<div class="worksheets">
    <div class="worksheets-wallpaper">
        <div class="worksheets-wrapper">
            <h1 class="title">Materiale Didactice</h1>
            <h2 class="subtitle">Fișe de lucru</h2>

            <div class="worksheets-content">
                <div>
                    <a class="document" href="../../../../assets/documents/fise-de-lucru/fisa de lucru M1 XI.docx">
                        <span>Fișă de lucru M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/fise-de-lucru/fisa de lucru M2 XI.docx">
                        <span>Fișă de lucru M2 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/fise-de-lucru/fisa de lucru M2.docx">
                        <span>Fișă de lucru M2 - clasa a XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Diagrama ciorchine Efect de  sera.docx">
                        <span>Diagrama ciorchine - Efect de seră</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru M1cls. XI Miniproiect Ambalaje din materiale plastice.docx">
                        <span>Fișă de lucru M1 - Miniproiect Ambalaje din materiale plastice clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru M2 cls. XII Recoltarea probelor de apa.docx">
                        <span>Fișă de lucru M2 - Recoltarea probelor de apă - clasa a XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru  M1 clasa a X-a.docx">
                        <span>Fișă de lucru M1 - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru clasa a XI-a M1.docx">
                        <span>Fișă de lucru M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru M1 clasa a XI-a.docx">
                        <span>Fișă de lucru M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru M2 clasa a X-a.docx">
                        <span>Fișă de lucru M2 - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-lucru/Fisa de lucru M3 clasa a XII-a.docx">
                        <span>Fișă de lucru M3 - clasa a XII-a</span>
                    </a>
                </div>
            </div>



        </div>
    </div>
</div>