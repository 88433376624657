<div class="materials">
    <div class="materials-wallpaper">
        <div class="materials-wrapper">

            <div class="container didactic-materials">
                <div class="row">
                    <div class="col">
                        <mat-card class="card" routerLink="/materiale-didactice/fise-de-lucru">
                            <mat-card-header>
                                <mat-card-title>Fișe de Lucru</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="content">
                                    <span class="material-icons-outlined icon">
                                        auto_stories
                                    </span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col">
                        <mat-card class="card" routerLink="/materiale-didactice/fise-de-documentare">
                            <mat-card-header>
                                <mat-card-title>Fișe de Documentare</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="content">
                                    <span class="material-icons-outlined icon">
                                        assignment
                                    </span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>


                </div>

                <div class="row">
                    <div class="col-sm">
                        <mat-card class="card" routerLink="/materiale-didactice/teste">
                            <mat-card-header>
                                <mat-card-title>Teste</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="content">
                                    <span class="material-icons-outlined icon">
                                        quiz
                                    </span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm">
                        <mat-card class="card" routerLink="/materiale-didactice/proiecte-didactice">
                            <mat-card-header>
                                <mat-card-title>Proiecte Didactice</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="content">
                                    <span class="material-icons-outlined icon">
                                        summarize
                                    </span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>