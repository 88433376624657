import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './components/contact/contact.component';
import { DidacticMaterialsComponent } from './components/didactic-materials/didactic-materials.component';

import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { HomeComponent } from './components/home/home.component';
import { BannerComponent } from './components/home/banner/banner.component';
import { ProfileDescriptionComponent } from './components/home/profile-description/profile-description.component';
import { EcologyComponent } from './components/home/ecology/ecology.component';
import { EcologyTechnicianComponent } from './components/home/ecology-technician/ecology-technician.component';
import { ActivitiesComponent } from './components/home/activities/activities.component';
import { ProfessionsComponent } from './components/home/professions/professions.component';
import { FacultiesComponent } from './components/home/faculties/faculties.component';
import { FooterComponent } from './components/footer/footer.component';
import { WorksheetsComponent } from './components/didactic-materials/worksheets/worksheets.component';
import { DocumentationSheetsComponent } from './components/didactic-materials/documentation-sheets/documentation-sheets.component';
import { TestsComponent } from './components/didactic-materials/tests/tests.component';
import { TeachingProjectsComponent } from './components/didactic-materials/teaching-projects/teaching-projects.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    DidacticMaterialsComponent,
    HomeComponent,
    BannerComponent,
    ProfileDescriptionComponent,
    EcologyComponent,
    EcologyTechnicianComponent,
    ActivitiesComponent,
    ProfessionsComponent,
    FacultiesComponent,
    FooterComponent,
    WorksheetsComponent,
    DocumentationSheetsComponent,
    TestsComponent,
    TeachingProjectsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatInputModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
