<div class="ecology">

    <div class="ecology-wallpaper">

        <div class="ecology-background">

            <div class="ecology-wrapper">
                <div class="image-container">
                    <img class="ecology-image" src="../../../../assets/ecology.jpg" />
                </div>


                <div class="content-container">
                    <div>
                        Pentru a arăta cât de importantă pentru viața unui om este pregătirea în domeniul protecției
                        mediului trebuie explicat termenul de <b>ecologie</b> - disciplină fundamentală a acestuia.
                        Etimologic, ecologie vine de la cuvintele greceşti <i>oikos</i>, care înseamnă "casă" şi
                        <i>logos</i> care însemană
                        "ştiinţă". Deci, am putea spune în sensul cel mai larg că elevii învăţă o meserie deosebit de
                        frumoasă, cea despre casa noastră – planeta Pământ, despre ce înseamnă grija faţă de ea, cum
                        trebuie
                        să o protejăm, cum să-i restabilim echilibrul atât de mult afectat, cum să ne dezvoltăm durabil
                        și
                        să avem o viață lungă și mai ales sănătoasă.

                    </div>

                </div>




            </div>



        </div>

    </div>

</div>