<div class="banner">

    <div class="banner-wallpaper">

        <div class="banner-background">

            <div class="banner-wrapper">
                <h2 class="title">Resurse Naturale și Protecția Mediului</h2>
                <h3 class="subtitle">Specializarea Tehnician Ecolog și Protecția Calității Mediului</h3>


            </div>



        </div>

    </div>

</div>