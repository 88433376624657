<div class="activities">

    <div class="activities-wallpaper">

        <div class="activities-background">

            <div class="activities-wrapper">
                <div class="activities-text">
                    <p>
                        Elevii care vor opta pentru această calificare vor învăţa să lucreze cu diferite aparate şi
                        instrumente de laborator pentru a efectua analize ale apei, aerului, solului şi să interpreteze
                        rezultatul acestora, să monitorizeze factorii de mediu, să aprecieze calitatea factorilor de
                        mediu,
                        noţiuni de legislaţia mediului, de managementul calităţii mediului.
                        Acestea sunt câteva argumente pentru a alege o meserie captivantă şi mai ales utilă pentru
                        viață.
                    </p>


                    <p>
                        Este un domeniu pe care ar trebui să se pună un accent deosebit, deoarece sănătatea noastră
                        depinde foarte mult de calitatea mediului, si reprezinta o problemă globală de actualitate ce ne
                        privește pe toți.
                    </p>

                    <p>
                        Absolvenţii domeniului <b>Resurse Naturale și Protecția Mediului</b>, după cei 4 ani de liceu
                        primesc
                        certificat de calificare nivel 4 <b>“Tehnician ecolog şi protecţia calităţii mediului”</b>.
                    </p>

                </div>




            </div>



        </div>

    </div>

</div>