import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { DidacticMaterialsComponent } from './components/didactic-materials/didactic-materials.component';
import { DocumentationSheetsComponent } from './components/didactic-materials/documentation-sheets/documentation-sheets.component';
import { TeachingProjectsComponent } from './components/didactic-materials/teaching-projects/teaching-projects.component';
import { TestsComponent } from './components/didactic-materials/tests/tests.component';
import { WorksheetsComponent } from './components/didactic-materials/worksheets/worksheets.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'materiale-didactice',
    component: DidacticMaterialsComponent,
  },
  {
    path: 'materiale-didactice/fise-de-lucru',
    component: WorksheetsComponent,
  },
  {
    path: 'materiale-didactice/fise-de-documentare',
    component: DocumentationSheetsComponent,
  },
  {
    path: 'materiale-didactice/teste',
    component: TestsComponent,
  },
  {
    path: 'materiale-didactice/proiecte-didactice',
    component: TeachingProjectsComponent,
  },
  {
    path: "**",
    redirectTo: "/",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
