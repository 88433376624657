<mat-sidenav-container fullscreen>
  <nav class="navbar navbar-expand-md navbar-dark">
    <a class="menu-item" mat-button routerLink="/">
      <img class="logo" src="../assets/logo-soft.png" />
      <span>Soft Protectia Mediului</span>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="menu-item" mat-button routerLink="/materiale-didactice" (click)="toggleNavbar()">
            <span>Materiale didactice</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="menu-item" mat-button routerLink="/contact" (click)="toggleNavbar()">
            <span>Contact</span>
          </a>
        </li>

      </ul>
    </div>
  </nav>

  <router-outlet></router-outlet>

</mat-sidenav-container>