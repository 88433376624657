<div class="profile-description">

    <div class="profile-description-wallpaper">

        <div class="profile-description-background">

            <div class="profile-description-wrapper">
                <div class="title-container">
                    <h2 class="title">Specificul profilului:</h2>
                </div>

                <div class="list-container">
                    <div class="bullet">
                        <span class="material-icons-outlined bullet-logo">
                            auto_stories
                        </span>
                        <div class="bullet-content">
                            Orientarea prioritară a educaţiei spre observarea şi interpretarea proceselor naturale care
                            au loc în mediu, spre înţelegerea impactului reciproc între mediu şi activităţile umane
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-logo">
                            science
                        </span>
                        <div class="bullet-content">
                            Accent deosebit pe aplicarea în practică a cunoştinţelor dobândite în orele de teorie, prin
                            studiu în laboratoarele de profil şi lecţii vizită la diferiţi agenţi economici
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-logo">
                            recycling
                        </span>
                        <div class="bullet-content">
                            Formarea unui comportament activ şi responsabil în domeniul protecţiei mediului, a unui stil
                            de viaţă sănătos
                        </div>
                    </div>

                </div>




            </div>



        </div>

    </div>

</div>