<div class="ecology-technician">

    <div class="ecology-technician-wallpaper">

        <div class="ecology-technician-background">

            <div class="ecology-technician-wrapper">

                <div class="content-container">
                    <div>
                        <b>Tehnicianul ecolog</b> realizează teste de laborator şi de teren pentru a monitoriza mediul
                        şi a
                        investiga sursele poluării, incluzând cele care afectează sănătatea.
                        Acesta colectează mostre de sol, apă sau alte materiale pentru testare şi luarea măsurilor
                        corective, în funcţie de sarcinile atribuite.
                        Verifică rapoartele zilnice de analiză a apelor, solului, supraveghează modul de funcţionare al
                        instalaţiilor de epurare ape uzate şi de reţinere noxe în aer, pregăteşte instalaţiile şi
                        aparatele
                        pentru experienţe, încercări şi analize, recoltează şi prepară probele supuse experimentării,
                        face
                        verificări la apele uzate evacuate, prin recoltarea de probe şi analizarea lor pe teren,
                        supraveghează modul în care se colectează, transportă şi depozitează deşeurile produse şi ţine
                        evidenţa lor lunară, întocmeşte rapoarte şi informări privind modul în care sunt respectate
                        prevederile legale privind protecţia mediului.
                    </div>

                </div>

                <div class="image-container">
                    <img class="ecology-image" src="../../../../assets/ecologists.jpg" />
                </div>




            </div>



        </div>

    </div>

</div>