<div class="faculties">

    <div class="faculties-wallpaper">

        <div class="faculties-background">

            <div class="faculties-wrapper">
                <div class="title-container">
                    <h2 class="title">Evoluția în carieră</h2>
                    <h3 class="subtitle">Învățământ superior:</h3>
                </div>

                <div class="list-container">
                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Design de Produs și Mediu
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Silvicultură și Exploatări Forestiere
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Ecologie și Protecția Mediului
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Imbunătăţiri Funciare şi Ingineria Mediului
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Chimie industrială şi Ingineria Mediului
                        </div>
                    </div>

                    <div class="bullet">
                        <span class="material-icons-outlined bullet-icon">
                            school
                        </span>
                        <div class="bullet-content">
                            Facultatea de Biotehnologii
                        </div>
                    </div>

                </div>




            </div>



        </div>

    </div>

</div>