<div class="worksheets">
    <div class="worksheets-wallpaper">
        <div class="worksheets-wrapper">
            <h1 class="title">Materiale Didactice</h1>
            <h2 class="subtitle">Proiecte Didactice</h2>

            <div class="worksheets-content">
                <div>
                    <a class="document"
                        href="../../../../assets/documents/proiecte-didactice/Pr. didactic consolidarea cunostintelor duritate.doc">
                        <span>Proiect didactic - Consolidarea cunoștințelor duritate</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/proiecte-didactice/Pr. didactic Determinarea ionilor de calciu si magneziu.docx">
                        <span>Proiect didactic - Determinarea ionilor de calciu și magneziu</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/proiecte-didactice/Proiect didactic deseurile din plastic.docx">
                        <span>Proiect didactic - Deșeurile din plastic</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>