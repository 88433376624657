<div class="worksheets">
    <div class="worksheets-wallpaper">
        <div class="worksheets-wrapper">
            <h1 class="title">Materiale Didactice</h1>
            <h2 class="subtitle">Teste</h2>

            <div class="worksheets-content">
                <div>
                    <a class="document" href="../../../../assets/documents/teste/Test de evaluare cls.X.docx">
                        <span>Test de evaluare - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/test evaluare sumativa M3 cl  X.doc">
                        <span>Test de evaluare sumativă M3 - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/TEST FINAL M2.doc">
                        <span>Test final M2 - clasa a XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/Test M1 X.docx">
                        <span>Test M1 - clasa a X-a </span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/TEST XII M2.docx">
                        <span>Test M2 - clasa a XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="http://triv.in/182950">
                        <span>Test triventy.com M2 - Monitorizarea calităţii apelor naturale</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="https://learningapps.org/view17371632">
                        <span>Test 1 learningapps.org M2 - Monitorizarea calităţii apelor naturale</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="https://learningapps.org/view17265925">
                        <span>Test 2 learningapps.org M2 - Monitorizarea calităţii apelor naturale</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/teste/Test de evaluare M2 cls. XII Determinarea suspensiilor totale si a turbiditatii.docx">
                        <span>Test de evaluare M2 - Determinarea suspensiilor totale și a turbidității - clasa a
                            XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/teste/Test evaluare M2 cls. XII Recoltarea,conservarea si transportul probelor de apa.docx">
                        <span>Test de evaluare M2 - Recoltarea, conservarea și transportul probelor de apă - clasa a
                            XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/Test evaluare Epurare.docx">
                        <span>Test de evaluare Epurare</span>
                    </a>
                    +
                    <a class="document"
                        href="../../../../assets/documents/teste/Barem de corectare si notare test eval.epurare.docx">
                        <span>Barem de corectare si notare</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/Test M1 clasa a XI-a.docx">
                        <span>Test M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/teste/TEST M3 cls IX.docx">
                        <span>Test M3 - clasa a IX-a</span>
                    </a>
                </div>

            </div>



        </div>
    </div>
</div>