<div class="worksheets">
    <div class="worksheets-wallpaper">
        <div class="worksheets-wrapper">
            <h1 class="title">Materiale Didactice</h1>
            <h2 class="subtitle">Fișe de documentare</h2>

            <div class="worksheets-content">
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Economia circulara M1 XI.docx">
                        <span>Economia circulară M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa de documentare - Tratarea namolurilor.docx">
                        <span>Fișă de documentare Tratarea nămolurilor M2 - clasa a XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Scheme epurarea biologica.docx">
                        <span>Scheme epurarea biologică</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa de documentare M1 cls X Informatii despre oxizii ce polueaza aerul.docx">
                        <span>Fișă de documentare M1 - Informații despre oxizii ce poluează aerul - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa de documentare M2 cls. XII Recoltarea conservarea si transportul probelor de apa.docx">
                        <span>Fișă de documentare M2 - Recoltarea, conservarea și transportul probelor de apă - clasa a
                            XII-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa de documentareM1 cls. X  Formarea atmosferei.docx">
                        <span>Fișă de documentare M1 - Formarea atmosferei - clasa a X-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa conspect M3 cls a IX-a APELE SUBTERANE.docx">
                        <span>Fișă conspect M3 - Apele subterane - clasa a IX-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa conspect M3 cls a-IX-a Bazine hidrog. Romania.docx">
                        <span>Fișă conspect M3 - Bazine gidrografice Romania - clasa a IX-a</span>
                    </a>
                </div>
                <div>
                    <a class="document"
                        href="../../../../assets/documents/fise-de-documentare/Fisa de documentare M1 clasa a XI-a.doc">
                        <span>Fișă de documentare M1 - clasa a XI-a</span>
                    </a>
                </div>
                <div>
                    <a class="document" href="../../../../assets/documents/fise-de-documentare/fisa suport M1 XI.docx">
                        <span>Fișă suport M1 - clasa a XI-a</span>
                    </a>
                </div>
            </div>



        </div>
    </div>
</div>