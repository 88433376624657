import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-worksheets',
  templateUrl: './worksheets.component.html',
  styleUrls: ['./worksheets.component.scss']
})
export class WorksheetsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
