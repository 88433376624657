<div class="contact">
    <div class="contact-wallpaper">
        <div class="contact-wrapper">
            <mat-card class="contact-card">

                <div class="contact-info">
                    <div class="contact site-creator">
                        <b>Administrator website:</b> Botezatu Violeta
                    </div>

                    <div class="contact school">
                        Profesor la Colegiul Tehnic "Gheorghe Bals" Adjud
                    </div>

                    <div class="contact email">
                        <b>Email:</b> botezatuvioleta25@yahoo.com
                    </div>

                    <div class="contact phone-number">
                        <b>Telefon:</b> 0757879273
                    </div>

                    <div class="contact padlet-soft">
                        <a href="https://padlet.com/violetabotezatu25/resursenaturalesiprotectiamediului">
                            <span>https://padlet.com/violetabotezatu25/resursenaturalesiprotectiamediului</span>
                        </a>
                    </div>
                </div>

            </mat-card>
        </div>

    </div>

</div>